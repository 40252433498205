/*
  File class
 */

import Vue from 'vue';
import BaseEntity from '@/core/classes/entity/BaseEntity';
import {
  COUNTRIES,
  ENTITY_TYPES,
  LICENSES,
} from '@/config/enums';
import {
  getApiClients,
  patchApiClient,
  deleteApiClient,
  getApiClient,
  postApiClient,
  putApiClient,
} from "@/api/repositories/apiClientsRepository";
import { formatDateTime } from "@/shared/utils";
import { Client, ClientUser, Subscription } from "@/entities";
import { DateTime } from "luxon";
import store from "@/store";
import { getClientDaughters } from "@/api/repositories/clientsRepository";

const defaultData = () => ({
  name: '',
  is_suspended: false,
  subscription_id: null,
  source_id: null,
  flattened_data: {},
  smart_settings: {
    language_id: null,
    currency_id: null,
    modules: [],
  },
});

export class ApiClient extends BaseEntity {
  static entityKey = 'api_client';
  static loadAllItemsFunc = getApiClients;
  static loadItemFunc = getApiClient;
  static deleteItemFunc = deleteApiClient;
  static postItemFunc = postApiClient;
  static putItemFunc = putApiClient;
  static patchItemFunc = patchApiClient;
  static datasetKey = 'apiClients';
  static entityTypeId = ENTITY_TYPES.API_CLIENT;
  static routes = {
    list: 'SupportApiClients',
    view: 'SupportApiClient',
    edit: 'SupportEditorApiClient',
    create: 'SupportEditorApiClientNew',
  };

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('api_clients.plural_api_clients_text.text');
  }

  constructor(...args) {
    super(defaultData, ...args);

    this.clientDaughters = [];
    this.clientParents = [];
    this.selfClass = ApiClient;
  }

  prepareItemData(data, defaultData) {
    const {
      client,
      subscription,
      ...rest
    } = data;

    this.subscription = subscription ? new Subscription(subscription) : null;
    this.client = client ? new Client(client) : null;

    _.merge(this.data, defaultData(), rest);
  }

  static getAllActions() {
    return [
      {
        key: 'view',
        name: Vue.prototype.$vDict('api_clients.view_button.text'),
        icon: 'pwd-show',
      },
      {
        key: 'edit',
        name: Vue.prototype.$vDict('api_clients.edit_button.text'),
        icon: 'edit',
      },
      {
        key: 'activate',
        icon: 'power',
        name: Vue.prototype.$vDict('api_clients.activate_button.text'),
        linkClass: 'action-link--secondary',
      },
      {
        type: 'divider',
      },
      {
        key: 'deactivate',
        icon: 'deactivated',
        name: Vue.prototype.$vDict('api_clients.deactivate_button.text'),
        linkClass: 'action-link--warning',
      },
      {
        key: 'delete',
        name: Vue.prototype.$vDict('api_clients.delete_button.text'),
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  getFieldValue(fieldKey) {
    let res;

    switch (fieldKey) {
      case 'subscription_end_date':
        res = this.subscription?.getValue('end_date') ?
          formatDateTime(this.subscription.getValue('end_date'), { toFormat: vars.LUXON_FORMAT_SHORT_DATE, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'status':
        res = this.getStatus();
        break;
      case 'client_id':
        res = this.getClient(dataset);
        break;
      default:
    }

    return res;
  }

  getClient(dataset = {}) {
    return this.getDatasetItem(dataset, Client.datasetKey, this.getValue('client_id'));
  }

  getStatus() {
    const expireAt = this.getValue('expire_at');
    let res;

    if (expireAt && DateTime.fromISO(expireAt) < DateTime.now()) {
      res = {
        class: 'status-tag--expired',
        name: Vue.prototype.$vDict('api_clients.status_expired.text'),
      };
    } else if (this.getValue('is_suspended')) {
      res = {
        class: 'status-tag--suspended',
        name: Vue.prototype.$vDict('api_clients.status_deactive.text'),
      };
    } else {
      res = {
        class: 'status-tag--active',
        name: Vue.prototype.$vDict('api_clients.status_active.text'),
      };
    }

    return res;
  }

  getActions() {
    const actions = this.selfClass.getAllActions();
    return actions.filter(action => {
      let res = true;

      switch (action.key) {
        case 'activate':
          res = this.getValue('is_suspended') && store.getters['Account/hasComponent']('api_client_deactivate_button');
          break;
        case 'deactivate':
          res = !this.getValue('is_suspended') && store.getters['Account/hasComponent']('api_client_deactivate_button');
          break;
        case 'delete':
          res = store.getters['Account/hasComponent']('api_client_delete_button');
          break;
        default:
      }

      return res;
    });
  }

  async doAction(action) {
    switch (action.key) {
      case 'activate':
        await this.changeSuspendedValue(false);
        break;
      case 'deactivate':
        await this.changeSuspendedValue(true);
        break;
      default:
        await super.doAction(action);
    }
  }

  async fetchSubscription() {
    const subscriptionId = this.getValue('subscription_id');

    if (!subscriptionId) {
      return;
    }

    try {
      const response = await Subscription.loadItemFunc(subscriptionId);
      this.subscription = new Subscription(response?.data || {});
    } catch (e) {}
  }

  async fetchClient() {
    const clientId = this.getValue('client_id');

    if (!clientId) {
      return;
    }

    try {
      const response = await Client.loadItemFunc(clientId);
      this.client = new Client(response?.data || {});
    } catch (e) {}
  }

  async fetchSubscriptionClient() {
    const clientId = this.getValue('client_id');

    if (!clientId) {
      return;
    }

    try {
      const response = await Client.loadItemFunc(clientId, {
        id_type: 'sharp',
      });
      this.client = new Client(response?.data || {});
    } catch (e) {}
  }

  async fetchClientDaughters() {
    const clientId = this.getValue('client_id');

    if (!clientId) {
      return;
    }

    const query = {
      limit: process.env.VUE_APP_MAX_LIMIT,
      filter: {
        client_id: {
          in: [clientId],
        },
      },
    };

    try {
      const response = await getClientDaughters(query);
      this.clientDaughters = response?.data || [];
    } catch (e) {}
  }

  async fetchClientParents() {
    const clientId = this.getValue('client_id');

    if (!clientId) {
      return;
    }

    const query = {
      limit: process.env.VUE_APP_MAX_LIMIT,
      filter: {
        daughter_id: {
          in: [clientId],
        },
      },
    };

    try {
      const response = await getClientDaughters(query);
      this.clientParents = response?.data || [];
    } catch (e) {}
  }

  isSubscriptionExpired() {
    if (!this.subscription) {
      return;
    }

    return this.subscription.isExpired();
  }
}
