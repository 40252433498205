/*
  DefaultFieldset class
 */

import Vue from 'vue';
import BaseEntity from '@/core/classes/entity/BaseEntity';
import {
  ENTITY_TYPES,
} from '@/config/enums';
import {
  deleteDefaultFieldset,
  getDefaultFieldsets,
  getDefaultFieldset,
  putDefaultFieldset,
  postDefaultFieldset,
} from "@/api/repositories/defaultFieldsetsRepository";
import { isJSON } from "@/shared/utils";
import { getEntityListConfigs } from "@/entities";
import { EntityListFactory } from "@/entities/EntityListFactory";

export class DefaultFieldset extends BaseEntity {
  static entityKey = 'default_fieldset';
  static datasetKey = 'defaultFieldsets';
  static entityTypeId = ENTITY_TYPES.DEFAULT_FIELDSET;
  static routes = {
    edit: 'SystemEditorDefaultFieldset',
    create: 'SystemEditorDefaultFieldsetNew',
    list: 'SystemDefaultFieldsets',
  };

  // api
  static loadAllItemsFunc = getDefaultFieldsets;
  static loadItemFunc = getDefaultFieldset;
  static putItemFunc = putDefaultFieldset;
  static postItemFunc = postDefaultFieldset;
  static deleteItemFunc = deleteDefaultFieldset;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('default_fieldsets.plural_default_fieldsets_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = DefaultFieldset;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  static getListTypes() {
    const listTypes = Vue.prototype.$getEnumsList('LIST_TYPES');
    const listsConfigs = getEntityListConfigs();

    return listTypes.filter(listType => listsConfigs[listType.id]?.customDefaultFieldset);
  }

  static getListsFieldsSections(listTypes = [], _countryIds) {
    const countryIds = _countryIds ||
      Vue.prototype.$lDict('global.countries').map(country => country.id);
    const res = [];

    listTypes.forEach(listType => {
      const entityList = EntityListFactory.create(listType.id);

      entityList.initFieldsSections(countryIds);
      entityList.initAllFields();
      res.push({
        listTypeId: listType.id,
        fieldsSections: entityList.fieldsSections,
        allFields: entityList.allFields,
      });
    });

    return res;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'country_id':
        res = Vue.prototype.$lFind('global.countries', { id: fieldValue, prop: 'name' });
        break;
      case 'list_type_id':
        value = this.getItemFromEnum('LIST_TYPES', fieldValue);
        res = value?.name || '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'fieldset':
        res = this.getFieldset(dataset);
        break;
      default:
    }

    return res;
  }

  getFieldset(dataset) {
    if (!dataset.listsFieldsSections) {
      return;
    }

    const listTypeId = this.getValue('list_type_id');
    const listFieldsSections = dataset.listsFieldsSections.findById(listTypeId);

    if (!listFieldsSections) {
      return;
    }

    const fsFields = listFieldsSections.allFields;
    const items = this.getValue('fieldset', []);
    const res = [];
    let fieldsetKeys;

    if (isJSON(items[0])) {
      const parsedData = JSON.parse(items[0]);
      fieldsetKeys = Array.isArray(parsedData) ? parsedData.map(el => el.key) : [];
    } else {
      fieldsetKeys = items;
    }

    fieldsetKeys.forEach(fieldKey => {
      const field = fsFields.findById(fieldKey);

      if (field?.label) {
        res.push(field);
      }
    });

    return res.length > 0 ? res : null;
  }
}
