/*
  EmailLogsList class
 */

import EntityList from "@/core/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  LIST_TYPES,
  SEARCHABLE_LIST_TYPES,
} from "@/config/enums";
import EmailLogTableView from "@/entities/email-log/views/EmailLogTableView";
import Vue from "vue";
import { getEmailLogsListsConfig } from "@/entities/email-log/config";
import { getEmailSenderLogs } from "@/api/repositories/emailSenderRepository";
import { requiredFields } from "@/entities/email-log/fields";
import { EmailLog } from "@/entities";

export class EmailLogsList extends EntityList {
  static entityClass = EmailLog;

  constructor(listTypeId) {
    super(listTypeId, getEmailLogsListsConfig);

    this.tableView = EmailLogTableView;

    this.selfClass = EmailLogsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'keywords',
        dataCy: 'keywords',
        name: Vue.prototype.$vDict('email_sender_logs.filter_keywords_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('email_sender_logs.filter_keywords_input_placeholder.text'),
        },
      },
      {
        key: 'email',
        dataCy: 'email',
        name: Vue.prototype.$vDict('email_sender_logs.filter_email_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('email_sender_logs.filter_email_input_placeholder.text'),
        },
        listTypeIds: [LIST_TYPES.ALL_EMAIL_LOGS],
      },
      {
        key: 'user_id',
        dataCy: 'user',
        name: Vue.prototype.$vDict('email_sender_logs.filter_user_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          type: SEARCHABLE_LIST_TYPES.USERS,
          placeholder: Vue.prototype.$vDict('email_sender_logs.filter_user_id_placeholder.text'),
        },
        listTypeIds: [LIST_TYPES.ALL_EMAIL_LOGS],
      },
      {
        key: 'provider_msg_id',
        dataCy: 'provider-msg-id',
        name: Vue.prototype.$vDict('email_sender_logs.filter_provider_msg_id_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('email_sender_logs.filter_provider_msg_id_input_placeholder.text'),
        },
      },
      {
        key: 'sent',
        dataCy: 'sent',
        name: Vue.prototype.$vDict('email_sender_logs.filter_sent_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: [
            {
              id: 0,
              name: Vue.prototype.$vDict('email_sender_logs.sent_status_failed.text'),
            }, {
              id: 1,
              name: Vue.prototype.$vDict('email_sender_logs.sent_status_success.text'),
            }
          ],
        },
      },
      {
        key: 'subject',
        dataCy: 'subject',
        name: Vue.prototype.$vDict('email_sender_logs.filter_subject_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: Vue.prototype.$lDict('client.notif_subjects').map(subject => ({
            id: subject.key,
            name: subject.name,
          })),
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('email_sender_logs.filter_created_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'email_provider_event_type_id',
        dataCy: 'event-type',
        name: Vue.prototype.$vDict('email_sender_logs.filter_event_type_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('EMAIL_PROVIDER_EVENT_TYPES'),
          showIcons: true,
          class: 'event-type-filter',
        },
      },
    ];
  }
}
