/*
  PMCustomFieldValuesList class
 */

import Vue from 'vue';
import EntityList from "@/core/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  PM_ENTITY_TYPES,
  VALUE_TYPES,
} from "@/config/enums";
import CustomFieldValuesTableView from "@/entities/parabyg-migration/pm-custom-field-value/views/CustomFieldValuesTableView";
import { getPMCustomFieldValueListsConfig } from "@/entities/parabyg-migration/pm-custom-field-value/config";
import { getPMCustomFieldValues } from "@/api/repositories/parabygMigrationRepository";
import { requiredFields } from "@/entities/parabyg-migration/pm-custom-field-value/fields";
import { PMCustomFieldValue } from "@/entities";
// import { Client } from "@/entities";

export class PMCustomFieldValuesList extends EntityList {
  static entityClass = PMCustomFieldValue;

  constructor(listTypeId) {
    super(listTypeId, getPMCustomFieldValueListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = CustomFieldValuesTableView;
    // this.settingsForLoadingDatasets = [
    //   {
    //     datasetKey: Client.datasetKey,
    //     loadFunc: Client.loadAllItemsFunc,
    //     fieldKeys: ['client_id'],
    //   },
    // ];

    this.selfClass = PMCustomFieldValuesList;
  }

  getListFiltersSetting() {
    const rbOptions = [
      { id: 0, name: Vue.prototype.$vDict('global.text_no.text') },
      { id: 1, name: Vue.prototype.$vDict('global.text_yes.text') },
      { id: -1, name: Vue.prototype.$vDict('parabyg_migration.filter_option_for_null_value.text') }
    ];

    return [
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('entity_list.field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
        },
      },
      {
        key: 'pm_client_version_id',
        dataCy: 'pm-client-version-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'pm_custom_field_id',
        dataCy: 'pm-custom-field-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_custom_field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'pm_entity_type_id',
        dataCy: 'pm-entity-type-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_entity_type_id.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: Object.keys(PM_ENTITY_TYPES).map(key => ({ id: PM_ENTITY_TYPES[key], name: key })),
        },
      },
      {
        key: 'pm_entity_id',
        dataCy: 'pm-entity-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_entity_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'entity_id',
        dataCy: 'entity-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_smart_entity_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'custom_field_id',
        dataCy: 'custom-field-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_custom_field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'display_name',
        dataCy: 'display-name',
        name: Vue.prototype.$vDict('parabyg_migration.field_display_name.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_display_name_input_placeholder.text'),
        },
      },
      {
        key: 'value_type',
        dataCy: 'value-type',
        name: Vue.prototype.$vDict('parabyg_migration.field_value_type.text'),
        // filterType: SEARCH_FILTER_TYPES.INPUT,
        // filterAttrs: {
        //   placeholder: Vue.prototype.$vDict('parabyg_migration.field_value_type_input_placeholder.text'),
        // },
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: Object.keys(VALUE_TYPES).map(key => ({ id: VALUE_TYPES[key], name: VALUE_TYPES[key] })),
        },
      },
      {
        key: 'hint',
        dataCy: 'hint',
        name: Vue.prototype.$vDict('parabyg_migration.field_hint.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_hint_input_placeholder.text'),
        },
      },
      {
        key: 'value_as_string',
        dataCy: 'value-as-string',
        name: Vue.prototype.$vDict('parabyg_migration.field_value_as_string.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_value_as_string_input_placeholder.text'),
        },
      },
      {
        key: 'reference_as_string',
        dataCy: 'reference-as-string',
        name: Vue.prototype.$vDict('parabyg_migration.field_reference_as_string.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_reference_as_string_input_placeholder.text'),
        },
      },
      {
        key: 'reference_source',
        dataCy: 'reference-source',
        name: Vue.prototype.$vDict('parabyg_migration.field_reference_source.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_reference_source_input_placeholder.text'),
        },
      },
      {
        key: 'is_valid',
        dataCy: 'is-valid',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
      {
        key: 'is_migrated',
        dataCy: 'is-migrated',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_migrated.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
    ];
  }
}
