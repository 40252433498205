/*
  PMDocument class
 */

import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getPMDocuments } from "@/api/repositories/parabygMigrationRepository";
import { Client } from "@/entities";
import { formatDateTime } from "@/shared/utils";
import Vue from "vue";

const defaultData = () => ({
  id: null,
  pm_client_version_id: null,
  source_id: null,
  responsible: null,
  responsible_ref: null,
  rsm_project_id: null,
  rsm_company_id: null,
  rsm_contact_id: null,
  created_at: null,
  modified_at: null,
  filename: '',
  url: '',
  is_valid: null,
  validation_error_message: null,
});

export class PMDocument extends BaseEntity {
  static entityKey = 'pm_document';
  static datasetKey = 'pmDocuments';
  static loadAllItemsFunc = getPMDocuments;
  static entityTypeId = ENTITY_TYPES.PM_DOCUMENT;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('parabyg_migration.documents_plural_text.text');
  }

  constructor(...args) {
    super(defaultData, ...args);

    this.selfClass = PMDocument;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
      case 'modified_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'client_id':
        res = this.getClient(dataset);
        break;
      default:
    }

    return res;
  }

  getClient(dataset = {}) {
    return this.getDatasetItem(dataset, Client.datasetKey, this.getValue('client_id'));
  }
}
