/*
  User profile class
 */

import Vue from 'vue';
import {
  ENTITY_TYPES,
} from "@/config/enums";
import BaseEntity from "@/core/classes/entity/BaseEntity";
import {
  getUserProfiles,
  getUserProfile,
  postUserProfile,
  patchUserProfile,
} from "@/api/repositories/userRepository";
import { SmartSelection } from "@/entities/smart-selection/SmartSelection";
import { User, ClientUser } from "@/entities";
import { formatDateTime } from "@/shared/utils";
import { BACKEND_ENDPOINTS } from "@/api/repositories/userRepository";

export class UserProfile extends BaseEntity {
  static entityKey = 'user_profile';
  static datasetKey = 'user_profiles';
  static entityTypeId = ENTITY_TYPES.USER;
  static navigationIdKey = 'user.id';

  // api
  static loadAllItemsEndpoint = BACKEND_ENDPOINTS.USER_PROFILES_QUERY;
  static loadAllItemsFunc = getUserProfiles;
  static loadItemFunc = getUserProfile;
  static patchItemFunc = patchUserProfile;
  static postItemFunc = postUserProfile;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('users.plural_users_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    // this.loadListFunc = getClients;
    this.selfClass = UserProfile;
  }

  static getAllActions() {
    const allUserActions = User.getAllActions()
      .map(action => ({
        ...action,
        type: 'user',
      }));

    return [
      ...allUserActions.filter(action => [
        'view',
        'edit',
        'send_magic_link',
        'transfer_data',
        'login_as',
      ].includes(action.key)),
      {
        type: 'divider',
      },
      {
        key: 'deactivate',
        icon: 'deactivated',
        name: Vue.prototype.$vDict('users.deactivate_user_on_subscription_button.text'),
        linkClass: 'action-link--warning',
      },
      {
        key: 'activate',
        icon: 'power',
        name: Vue.prototype.$vDict('users.activate_user_on_subscription_button.text'),
        linkClass: 'action-link--secondary',
      },
      {
        type: 'divider',
      },
      ...allUserActions.filter(action => [
        'suspend',
        'unsuspend',
      ].includes(action.key)),
    ];
  }

  prepareItemData(data, defaultData) {
    const {
      smart_selections = [],
      user,
      client_user,
      ...rest
    } = data;

    this.user = user ? new User(user, this.listInstance) : null;
    this.selections = (smart_selections || []).map(selectionData => new SmartSelection(selectionData));
    this.clientUser = client_user ? new ClientUser(client_user) : null;
    this.navigationId = this.user?.id;

    _.merge(this.data, defaultData(), rest);
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'user_id':
        res = this.user?.id;
        break;
      case 'user_email':
        res = this.user?.getValue('email');
        break;
      case 'modified_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'license_id':
        value = this.clientUser?.getLicense();
        res = value?.name;
        break;
      case 'invited_at':
        res = this.clientUser?.getFieldValue('smart_settings.invited_at') || '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;
    let value;

    switch (fieldKey) {
      case 'status':
        res = this.getStatus();
        break;
      case 'account_status':
        res = this.user?.getAccountStatus() || null;
        break;
      case 'client_user_status':
        res = this.clientUser?.getUserOnboardingStatus() || null;
        break;
      default:
        res = undefined;
    }

    return res;
  }

  getStatus() {
    let res;

    if (this.getValue('is_suspended')) {
      res = {
        variant: 'deactivated',
        name: Vue.prototype.$vDict('users.user_profile_status_suspended.text'),
      };
    } else {
      res = {
        variant: 'active',
        name: Vue.prototype.$vDict('users.user_profile_status_active.text'),
      };
    }

    return res;
  }

  async patchModules(payload = {}) {
    const { modules, fixed_modules } = payload;
    const patchData = {
      modules,
    };

    if (fixed_modules) {
      patchData.fixed_modules = fixed_modules;
    }

    const query = Object.entries(patchData).reduce((acum, [key, value]) => ({
      ...acum,
      [`smart_settings/${key}`]: value,
    }), {});

    await this.selfClass.patchItemFunc(this.id, query);
    Object.assign(this.data.smart_settings, patchData);
  }

  async doAction(action) {
    if (action.type === 'user') {
      await this.user?.doAction(action);
      return;
    }

    switch (action.key) {
      case 'deactivate':
        await this.changeSuspendedValue(true);
        break;
      case 'activate':
        await this.changeSuspendedValue(false);
        break;
      default:
        await super.doAction(action);
    }
  }

  async changeSuspendedValue(is_suspended) {
    const res = await super.changeSuspendedValue(is_suspended);

    if (!res || !this.user.userProfiles.length) {
      return;
    }

    const userProfile = this.user.userProfiles.find(userProfile => userProfile.id === this.id);

    if (userProfile) {
      userProfile.setValue('is_suspended', is_suspended);
    }
  }

  getActions() {
    const allActions = this.selfClass.getAllActions();
    const userActions = this.user?.getActions() || [];

    return allActions.filter(action => {
      if (action.type === 'user') {
        return userActions.some(userAction => userAction.key === action.key);
      }

      let res = true;

      switch (action.key) {
        case 'deactivate':
          res = !this.getValue('is_suspended');
          break;
        case 'activate':
          res = this.getValue('is_suspended');
          break;
        default:
      }

      return res;
    });
  }
  isActive() {
    return !this.getValue('is_suspended');
  }
}
