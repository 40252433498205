/*
  VortalContact class
 */

import Vue from 'vue';
import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getContacts, patchContact } from "@/api/repositories/vortalConnectRepository";
import { formatDateTimeNoWrap } from "@/shared/utils";

export class VortalContact extends BaseEntity {
  static entityKey = 'vortal_contact';
  static datasetKey = 'vortalContacts';
  static entityTypeId = ENTITY_TYPES.VORTAL_CONTACT;
  static routes = {
    edit: 'VortalConnectEditorContact',
    list: 'VortalConnectContacts',
  };

  // api
  static loadAllItemsFunc = getContacts;
  static patchItemFunc = patchContact;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('vortal_connect_contacts.contacts_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = VortalContact;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
      case 'modified_at':
        res = fieldValue ? formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'country_id':
        res = Vue.prototype.$lFind('global.countries', { id: fieldValue, prop: 'name' });
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'vortal_company_id':
        value = this.getDatasetItem(dataset, 'vortalCompanies', fieldValue);
        res = value?.name || '';
        break;
      default:
    }

    return res;
  }
}
