/*
  DefaultFieldsetsList class
 */

import EntityList from "@/core/classes/list/EntityList";
import { SEARCH_FILTER_TYPES } from "@/config/enums";
import DefaultFieldsetsTableView from "@/entities/default-fieldset/views/DefaultFieldsetsTableView";
import Vue from "vue";
import { getDefaultFieldsetsListsConfig } from "@/entities/default-fieldset/config";
import { requiredFields } from "@/entities/default-fieldset/fields";
import { getIndexedArray, IndexedArray } from "@/shared/proxies";
import { DefaultFieldset } from "@/entities";

export class DefaultFieldsetsList extends EntityList {
  static entityClass = DefaultFieldset;

  constructor(listTypeId) {
    super(listTypeId, getDefaultFieldsetsListsConfig);

    this.tableView = DefaultFieldsetsTableView;

    this.selfClass = DefaultFieldsetsList;
    this.getRequiredFields = requiredFields;
  }

  async loadDataForInitList() {
    const { entityClass } = this.selfClass;
    const listIndexedArray = getIndexedArray('listTypeId');
    const listTypes = entityClass.getListTypes();
    const listsFieldsSections = entityClass.getListsFieldsSections(listTypes);

    Vue.set(this.dataset, 'listTypes', new IndexedArray(listTypes));
    Vue.set(this.dataset, 'listsFieldsSections', new listIndexedArray(listsFieldsSections));

    return this.dataset;
  }

  getListFiltersSetting(payload = {}) {
    return [
      {
        key: 'country_id',
        dataCy: 'country',
        name: 'Country',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.countries'),
        },
      },
      {
        key: 'list_type_id',
        dataCy: 'list-type',
        name: 'List type',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: payload.listTypes || [],
        },
      },
    ];
  }
}
