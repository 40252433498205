/*
  Component class
 */

import BaseEntity from '@/core/classes/entity/BaseEntity';
import {
  ENTITY_TYPES,
} from '@/config/enums';
import {
  deleteComponent,
  getComponents,
  getComponent,
  putComponent,
  postComponent,
} from "@/api/repositories/systemRepository";
import Vue from "vue";

export class Component extends BaseEntity {
  static entityKey = 'component';
  static datasetKey = 'components';
  static entityTypeId = ENTITY_TYPES.COMPONENT;
  static routes = {
    edit: 'SystemEditorComponent',
    create: 'SystemEditorComponentNew',
    list: 'SystemComponents',
  };

  // api
  static loadAllItemsFunc = getComponents;
  static deleteItemFunc = deleteComponent;
  static loadItemFunc = getComponent;
  static postItemFunc = postComponent;
  static putItemFunc = putComponent;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('system_components.components_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = Component;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }
}
