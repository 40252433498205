/*
  UserProfilesList class
 */

import EntityList from "@/core/classes/list/EntityList";
import UserProfilesTableView from "@/entities/user-profile/table-views/SubscriptionUsersTableView.vue";
import Vue from "vue";
import { getUserProfilesListsConfig } from "@/entities/user-profile/config";
import { UserProfile } from "@/entities";

export class UserProfilesList extends EntityList {
  static entityClass = UserProfile;

  constructor(listTypeId) {
    super(listTypeId, getUserProfilesListsConfig);

    this.tableView = UserProfilesTableView;

    this.selfClass = UserProfilesList;
  }
}
