/*
  DeadMessage class
 */

import Vue from 'vue';
import BaseEntity from '@/core/classes/entity/BaseEntity';
import {
  ENTITY_TYPES,
} from '@/config/enums';
import { formatDateTime } from "@/shared/utils";
import {
  resendDeadMessage,
  getDeadMessages,
  deleteDeadMessage,
  getDeadMessage,
  patchDeadMessage,
} from "@/api/repositories/dictionariesRepository";

export class DeadMessage extends BaseEntity {
  static entityKey = 'dead_message';
  static datasetKey = 'deadMessages';
  static entityTypeId = ENTITY_TYPES.DEAD_MESSAGE;
  static routes = {
    edit: 'SystemDeadMessageEditor',
    list: 'SystemDeadMessages',
  };

  // api
  static loadAllItemsFunc = getDeadMessages;
  static loadItemFunc = getDeadMessage;
  static patchItemFunc = patchDeadMessage;
  static deleteItemFunc = deleteDeadMessage;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('dead_messages.dead_messages_plural.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = DeadMessage;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
      {
        key: 'resend',
        icon: 'recycle',
        name: Vue.prototype.$vDict('dead_messages.dead_message_resend_button.text'),
      },
      {
        key: 'subscribe',
        icon: 'notebook-pen',
        name: Vue.prototype.$vDict('dead_messages.dead_message_subscribe_button.text'),
      },
      {
        type: 'divider',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
      case 'failed_at':
      case 'resent_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  async resend() {
    try {
      const response = await resendDeadMessage(this.getValue('id'));

      if (response === true) {
        Vue.prototype.$notifToastr('success', Vue.prototype.$vDict('dead_messages.dead_message_resend_success_text.text'));
      }
    } catch (e) {
      Vue.prototype.$notifUnexpectedError();
    }
  }

  async doAction(action) {
    switch (action.key) {
      case 'resend':
        await this.resend();
        break;
      default:
        await super.doAction(action);
    }
  }
}
