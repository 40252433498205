/*
  UsageStatsClientsList class
 */

import Vue from "vue";
import EntityList from "@/core/classes/list/EntityList";
import UsageStatsClientsTableView from "@/entities/usage-stats-client/views/UsageStatsClientsTableView";
import { getUsageStatsClientsListsConfig } from "@/entities/usage-stats-client/config";
import { requiredFields } from "@/entities/usage-stats-client/fields";
import { getUSClients } from '@/api/repositories/usageStatisticsRepository';
import { UsageStatsClient } from "@/entities";

export class UsageStatsClientsList extends EntityList {
  static entityClass = UsageStatsClient;

  constructor(listTypeId) {
    super(listTypeId, getUsageStatsClientsListsConfig);

    this.tableView = UsageStatsClientsTableView;

    this.selfClass = UsageStatsClientsList;
    this.getRequiredFields = requiredFields;
  }

  // prepareJSFilter(jsFilter = {}) {
  //   const period = store.getters['UsageStats/getPeriod'];
  //
  //   return {
  //     ...jsFilter,
  //     date: period.date,
  //   };
  // }
}
