/*
  PMCustomFieldEmployeesList class
 */

import Vue from 'vue';
import EntityList from "@/core/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import CustomFieldEmployeesTableView from "@/entities/parabyg-migration/pm-custom-field-employee/views/CustomFieldEmployeesTableView";
import { getPMCustomFieldEmployeeListsConfig} from "@/entities/parabyg-migration/pm-custom-field-employee/config";
import { getPMCustomFieldEmployees } from "@/api/repositories/parabygMigrationRepository";
import { requiredFields } from "@/entities/parabyg-migration/pm-custom-field-employee/fields";
import { PMCustomFieldEmployee } from "@/entities";
// import { Client } from "@/entities";

export class PMCustomFieldEmployeesList extends EntityList {
  static entityClass = PMCustomFieldEmployee;

  constructor(listTypeId) {
    super(listTypeId, getPMCustomFieldEmployeeListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = CustomFieldEmployeesTableView;
    // this.settingsForLoadingDatasets = [
    //   {
    //     datasetKey: Client.datasetKey,
    //     loadFunc: Client.loadAllItemsFunc,
    //     fieldKeys: ['client_id'],
    //   },
    // ];

    this.selfClass = PMCustomFieldEmployeesList;
  }

  getListFiltersSetting() {
    const rbOptions = [
      { id: 0, name: Vue.prototype.$vDict('global.text_no.text') },
      { id: 1, name: Vue.prototype.$vDict('global.text_yes.text') },
      { id: -1, name: Vue.prototype.$vDict('parabyg_migration.filter_option_for_null_value.text') }
    ];

    return [
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('entity_list.field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
        },
      },
      {
        key: 'pm_client_version_id',
        dataCy: 'pm-client-version-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'pm_custom_field_id',
        dataCy: 'pm-custom-field-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_custom_field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'pm_employee_id',
        dataCy: 'pm-employee-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_employee_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'source_id',
        dataCy: 'source-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_source_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'email',
        dataCy: 'email',
        name: Vue.prototype.$vDict('parabyg_migration.field_email.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_email_input_placeholder.text'),
        },
      },
      {
        key: 'active',
        dataCy: 'active',
        name: Vue.prototype.$vDict('parabyg_migration.field_active.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
      {
        key: 'is_valid',
        dataCy: 'is-valid',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
      {
        key: 'is_migrated',
        dataCy: 'is-migrated',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_migrated.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
    ];
  }
}
