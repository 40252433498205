/*
  MyranSubscriptionVersionsList class
 */

import Vue from "vue";
import EntityList from "@/core/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  LIST_TYPES,
} from "@/config/enums";
import MyranSubscriptionVersionsTableView from "@/entities/myran-subscription-version/views/MyranSubscriptionVersionsTableView";
import { getMyranSubscriptionVersionsListsConfig } from "@/entities/myran-subscription-version/config";
import { requiredFields } from "@/entities/myran-subscription-version/fields";
import { getSubscriptionVersions } from '@/api/repositories/myranRepository';
import { MyranSubscriptionVersion } from "@/entities";

export class MyranSubscriptionVersionsList extends EntityList {
  static entityClass = MyranSubscriptionVersion;

  constructor(listTypeId) {
    super(listTypeId, getMyranSubscriptionVersionsListsConfig);

    this.tableView = MyranSubscriptionVersionsTableView;

    this.selfClass = MyranSubscriptionVersionsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'status',
        dataCy: 'status',
        name: 'Status',
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: [
            { id: 1, name: 'Live' },
          ],
        },
        listTypeIds: [LIST_TYPES.SUBSCRIPTION_MYRAN_UPDATES],
      },
      {
        key: 'keywords',
        dataCy: 'keywords',
        name: 'Keywords',
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: 'Type keywords',
        },
      },
      {
        key: 'id',
        dataCy: 'id',
        name: 'Id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type id',
        },
      },
      {
        key: 'subscription_customer_id',
        dataCy: 'subscription-customer',
        name: 'Subscription customer id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type subscription customer id',
        },
      },
      {
        key: 'source_type_id',
        dataCy: 'source-type',
        name: 'Source type',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$getEnumsList('SOURCE_TYPES'),
        },
      },
      {
        key: 'source_id',
        dataCy: 'source',
        name: 'Source Id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type source id',
        },
        listTypeIds: [LIST_TYPES.MYRAN_LOGS_SUBSCRIPTION_VERSIONS],
      },
      {
        key: 'source_customer_id',
        dataCy: 'source-customer',
        name: 'Source customer id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type source customer id',
        },
        listTypeIds: [LIST_TYPES.MYRAN_LOGS_SUBSCRIPTION_VERSIONS],
      },
      {
        key: 'source_created_at',
        dataCy: 'source-created-at',
        name: 'Source created at',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'source_modified_at',
        dataCy: 'source-modified-at',
        name: 'Source modified at',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'support_name',
        dataCy: 'support-name',
        name: 'Support name',
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: 'Type support name',
        },
      },
      {
        key: 'support_email',
        dataCy: 'support-email',
        name: 'Support email',
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: 'Type support email',
        },
      },
      {
        key: 'start_date',
        dataCy: 'start-date',
        name: 'Start date',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'end_date',
        dataCy: 'end-date',
        name: 'End date',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'expiration_date',
        dataCy: 'expiration-date',
        name: 'Expiration date',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: 'Created at',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
    ];
  }
}
