/*
  VortalNotificationEmailsList class
 */

import EntityList from "@/core/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import VortalNotificationEmailsTableView from "@/entities/vortal-notification-email/views/VortalNotificationEmailsTableView";
import Vue from "vue";
import { getVortalNotificationEmailsListsConfig } from "@/entities/vortal-notification-email/config";
import { getNotificationEmails, deleteNotificationEmail } from "@/api/repositories/vortalConnectRepository";
import { requiredFields } from "@/entities/vortal-notification-email/fields";
import { VortalNotificationEmail } from "@/entities";

export class VortalNotificationEmailsList extends EntityList {
  static entityClass = VortalNotificationEmail;

  constructor(listTypeId) {
    super(listTypeId, getVortalNotificationEmailsListsConfig);

    this.tableView = VortalNotificationEmailsTableView;

    this.selfClass = VortalNotificationEmailsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'email',
        dataCy: 'email',
        name: Vue.prototype.$vDict('vortal_connect_notif_emails.filter_email_label.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('vortal_connect_notif_emails.filter_email_input_placeholder.text'),
        },
      },
      {
        key: 'country_id',
        dataCy: 'country',
        name: Vue.prototype.$vDict('vortal_connect_notif_emails.filter_country_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.countries'),
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('vortal_connect_notif_emails.filter_created_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'modified_at',
        dataCy: 'modified-at',
        name: Vue.prototype.$vDict('vortal_connect_notif_emails.filter_modified_at_label.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
    ];
  }
}
