/*
  PMCompany class
 */

import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getPMCompanies } from "@/api/repositories/parabygMigrationRepository";
import { Company } from "@/entities";
import { formatDateTime } from "@/shared/utils";
import Vue from "vue";

const defaultData = () => ({
  id: null,
  pm_client_version_id: null,
  source_id: null,
  parabyg_id: null,
  rsm_id: null,
  entity_id: null,
  is_valid: null,
  validation_error_message: null,
  is_migrated: null,
  migration_error_message: null,
});

export class PMCompany extends BaseEntity {
  static entityKey = 'pm_company';
  static datasetKey = 'pmCompanies';
  static loadAllItemsFunc = getPMCompanies;
  static entityTypeId = ENTITY_TYPES.PM_COMPANY;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('parabyg_migration.companies_plural_text.text');
  }

  constructor(...args) {
    super(defaultData, ...args);

    this.selfClass = PMCompany;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'entity_id':
        res = this.getSmartCompany(dataset);
        break;
      default:
    }

    return res;
  }

  getSmartCompany(dataset = {}) {
    return this.getDatasetItem(dataset, Company.datasetKey, this.getValue('entity_id'));
  }
}
