/*
  DeadMessagesList class
 */

import EntityList from "@/core/classes/list/EntityList";
import { ENTITY_TYPES, SEARCH_FILTER_TYPES } from "@/config/enums";
import DeadMessagesTableView from "@/entities/dead-message/views/DeadMessagesTableView";
import Vue from "vue";
import { getDeadMessagesListsConfig } from "@/entities/dead-message/config";
import { getDeadMessages, deleteDeadMessage } from "@/api/repositories/dictionariesRepository";
import { requiredFields } from "@/entities/dead-message/fields";
import { DeadMessage } from "@/entities";

export class DeadMessagesList extends EntityList {
  static entityClass = DeadMessage;

  constructor(listTypeId) {
    super(listTypeId, getDeadMessagesListsConfig);

    this.tableView = DeadMessagesTableView;

    this.selfClass = DeadMessagesList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('dead_messages.field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('search.input_id_placeholder.text'),
        },
      },
      {
        key: 'service_name',
        dataCy: 'service-name',
        name: Vue.prototype.$vDict('dead_messages.field_service_name.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
      },
      {
        key: 'topic',
        dataCy: 'topic',
        name: Vue.prototype.$vDict('dead_messages.field_topic.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
      },
      {
        key: 'schema_name',
        dataCy: 'schema-name',
        name: Vue.prototype.$vDict('dead_messages.field_schema_name.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
      },
      {
        key: 'idempotency_key',
        dataCy: 'idempotency-key',
        name: Vue.prototype.$vDict('dead_messages.field_idempotency_key.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
      },
      {
        key: 'request_id',
        dataCy: 'request-id',
        name: Vue.prototype.$vDict('dead_messages.field_request_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: Vue.prototype.$vDict('dead_messages.field_created_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'failed_at',
        dataCy: 'failed-at',
        name: Vue.prototype.$vDict('dead_messages.field_failed_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'resent_at',
        dataCy: 'resent-at',
        name: Vue.prototype.$vDict('dead_messages.field_resent_at.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
      {
        key: 'payload',
        dataCy: 'payload',
        name: Vue.prototype.$vDict('dead_messages.field_payload.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
      },
      {
        key: 'reason',
        dataCy: 'reason',
        name: Vue.prototype.$vDict('dead_messages.field_reason.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
      },
    ];
  }
}
