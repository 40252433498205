/*
  PMContact class
 */

import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getPMContacts } from "@/api/repositories/parabygMigrationRepository";
import { Contact } from "@/entities";
import { formatDateTime } from "@/shared/utils";
import Vue from "vue";

const defaultData = () => ({
  id: null,
  pm_client_version_id: null,
  source_id: null,
  parabyg_id: null,
  rsm_id: null,
  entity_id: null,
  is_valid: null,
  validation_error_message: null,
  is_migrated: null,
  migration_error_message: null,
});

export class PMContact extends BaseEntity {
  static entityKey = 'pm_contact';
  static datasetKey = 'pmContacts';
  static loadAllItemsFunc = getPMContacts;
  static entityTypeId = ENTITY_TYPES.PM_CONTACT;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('parabyg_migration.contacts_plural_text.text');
  }

  constructor(...args) {
    super(defaultData, ...args);

    this.selfClass = PMContact;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'entity_id':
        res = this.getSmartContact(dataset);
        break;
      default:
    }

    return res;
  }

  getSmartContact(dataset = {}) {
    return this.getDatasetItem(dataset, Contact.datasetKey, this.getValue('entity_id'));
  }
}
