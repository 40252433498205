import { render, staticRenderFns } from "./ModulesTableView.vue?vue&type=template&id=ae8d6c0c&scoped=true&"
import script from "./ModulesTableView.vue?vue&type=script&lang=js&"
export * from "./ModulesTableView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae8d6c0c",
  null
  
)

export default component.exports