/*
  UsageStatsClient class
 */

import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getUSClients } from '@/api/repositories/usageStatisticsRepository';
import { getDateTextOffsetToday } from "@/shared/utils";
import Vue from "vue";

export class UsageStatsClient extends BaseEntity {
  static entityKey = 'usage_stats_client';
  static datasetKey = 'usageStatsClients';
  static idKey = 'client_id';
  static entityTypeId = ENTITY_TYPES.USAGE_STATS_CLIENT;
  static navigationIdKey = 'client_id';

  // api
  static loadAllItemsFunc = getUSClients;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('clients.plural_clients_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = UsageStatsClient;
    this.id = this.getValue(this.selfClass.idKey);
    this.navigationId = this.getValue(this.selfClass.navigationIdKey);
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'last_login':
        res = fieldValue ? getDateTextOffsetToday(fieldValue) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }
}
