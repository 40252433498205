/*
  PMDocumentsList class
 */

import Vue from 'vue';
import EntityList from "@/core/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import DocumentsTableView from "@/entities/parabyg-migration/pm-document/views/DocumentsTableView";
import { getPMDocumentListsConfig } from "@/entities/parabyg-migration/pm-document/config";
import { getPMDocuments } from "@/api/repositories/parabygMigrationRepository";
import { requiredFields } from "@/entities/parabyg-migration/pm-document/fields";
import { PMDocument } from "@/entities";

// import { Client } from "@/entities";

export class PMDocumentsList extends EntityList {
  static entityClass = PMDocument;

  constructor(listTypeId) {
    super(listTypeId, getPMDocumentListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = DocumentsTableView;
    // this.settingsForLoadingDatasets = [
    //   {
    //     datasetKey: Client.datasetKey,
    //     loadFunc: Client.loadAllItemsFunc,
    //     fieldKeys: ['client_id'],
    //   },
    // ];

    this.selfClass = PMDocumentsList;
  }

  getListFiltersSetting() {
    const rbOptions = [
      { id: 0, name: Vue.prototype.$vDict('global.text_no.text') },
      { id: 1, name: Vue.prototype.$vDict('global.text_yes.text') },
      { id: -1, name: Vue.prototype.$vDict('parabyg_migration.filter_option_for_null_value.text') }
    ];

    return [
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('entity_list.field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
        },
      },
      {
        key: 'pm_client_version_id',
        dataCy: 'pm-client-version-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'source_id',
        dataCy: 'source-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_source_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'is_valid',
        dataCy: 'is-valid',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_fetched.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
    ];
  }
}
