import BaseEntity from "@/core/classes/entity/BaseEntity";
import Vue from "vue";
import { User, Client, Subscription } from "@/entities";
import router from "@/router";
import {
  getToken,
  inviteUser,
} from "@/api/repositories/userRepository";
import {
  getClientUsers,
  getClientUser,
  patchClientUser,
} from "@/api/repositories/clientsRepository";
import store from "@/store";
import { formatDateTime } from "@/shared/utils";
import {
  SENDING_MODES,
  USER_TOKEN_TYPES,
  ENV_MODES,
  SERVER_ERROR_CODES,
  SMART_USER_STATUSES,
  INVITE_STATUSES,
} from "@/config/enums";
import {
  BACKEND_ENDPOINTS
} from "@/api/repositories/clientsRepository";
import { token } from "@/api/repositories/authRepository";

export class ClientUser extends BaseEntity {
  static loadAllItemsEndpoint = BACKEND_ENDPOINTS.CLIENT_USERS_QUERY;
  static loadAllItemsFunc = getClientUsers;
  static loadItemFunc = getClientUser;
  static patchItemFunc = patchClientUser;
  static navigationIdKey = 'user.id';

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('users.plural_users_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = ClientUser;
  }

  prepareItemData(data, defaultData) {
    const {
      user,
      client,
      subscriptions = [],
      ...rest
    } = data;

    this.user = user ? new User(user, this.listInstance) : null;
    this.client = client ? new Client(client) : null;
    this.subscriptions = (subscriptions || []).map(subscriptionData => new Subscription(subscriptionData));
    this.navigationId = this.user?.id;

    _.merge(this.data, defaultData(), rest);
  }

  static getAllActions() {
    const allUserActions = User.getAllActions()
      .map(action => ({
        ...action,
        type: 'user',
      }));

    return [
      ...allUserActions.filter(action => [
        'view',
        'edit',
        'send_magic_link',
        'transfer_data',
        'login_as',
      ].includes(action.key)),
      {
        key: 'view_client',
        name: Vue.prototype.$vDict('users.view_client_button.text'),
        icon: 'pwd-show',
      },
      {
        key: 'edit_client',
        name: Vue.prototype.$vDict('users.edit_client_user_settings_button.text'),
        icon: 'edit',
      },
      {
        key: 'send_magic_link',
        dataCy: 'send-magic-link',
        name: Vue.prototype.$vDict('users.send_magic_link_button.text'),
        icon: 'magic_link',
      },
      {
        key: 'send_invite',
        dataCy: 'send-invite',
        name: Vue.prototype.$vDict('users.send_invite_button.text'),
        icon: 'invite',
      },
      {
        key: 'copy_invite_link',
        dataCy: 'copy-invite-link',
        icon: 'link',
        name: Vue.prototype.$vDict('users.copy_invite_link_button.text'),
      },
      {
        key: 'view_stats',
        dataCy: 'view-stats',
        icon: 'stats',
        name: Vue.prototype.$vDict('users.usage_stats_button.text'),
      },
      {
        key: 'add_to_organization',
        dataCy: 'add-to-subscription',
        name: Vue.prototype.$vDict('users.add_user_to_subscription_button.text'),
        icon: 'lead-info',
      },
      {
        type: 'divider',
      },
      ...allUserActions.filter(action => [
        'suspend',
        'unsuspend',
      ].includes(action.key)),
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'smart_settings.invited_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE, userZone: true }) : '';
        break;
      case 'user.modified_at':
        res = this.user.getFieldValue('modified_at');
        break;
      case 'smart_settings.license_id':
        value = this.getLicense();
        res = value?.name || '';
        break;
      case 'smart_settings.status':
        res = this.getUserOnboardingStatus();
        break;
      case 'smart_settings.invite_status':
        value = this.getInviteStatus();
        res = value?.name || '';
        break;
      case 'smart_settings.language_id':
        value = this.getLanguage();
        res = value?.name || '';
        break;
      case 'smart_settings.position_id':
        value = this.getPosition();
        res = value?.name || '';
        break;
      case 'smart_settings.currency_id':
        value = this.getCurrency();
        res = value?.name || '';
        break;
      case 'smart_settings.updates_notif_mode':
        res = this.getUpdatesNotifTimeAsText();
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getInviteStatus() {
    return this.getItemFromEnum('INVITE_STATUSES', this.getValue('smart_settings.invite_status'));
  }

  getLicense() {
    return Vue.prototype.$lFind('client.licenses', {
      id: this.getValue('smart_settings.license_id')
    });
  }

  getPosition() {
    return Vue.prototype.$lFind('user_profile.user_positions', {
      id: this.getValue('smart_settings.position_id'),
    });
  }

  getLanguage() {
    return Vue.prototype.$lFind('global.languages', {
      id: this.getValue('smart_settings.language_id'),
    });
  }

  getCurrency() {
    return Vue.prototype.$lFind('global.currencies', {
      id: this.getValue('smart_settings.currency_id'),
    });
  }

  getUpdatesNotifTimeAsText() {
    const notifMode = this.getValue('smart_settings.updates_notif_mode');
    let res = notifMode?.time;

    if (!res) {
      return '';
    }

    if (notifMode.sending_mode === SENDING_MODES.DAILY) {
      res = Vue.prototype.$vDict('global.daily_updates_time.text', {
        time: notifMode.time,
      });
    } else if (notifMode.sending_mode === SENDING_MODES.WEEKLY) {
      const daysOfWeek = Vue.prototype.$lDict('global.days_of_week');
      const dayOfWeek = daysOfWeek.find(day => day.week_human_id === notifMode.day_of_week);

      res = Vue.prototype.$vDict('global.weekly_updates_time.text', {
        day: dayOfWeek?.name || '',
        time: notifMode.time,
      });
    }

    return res;
  }

  getUserOnboardingStatus() {
    const status = this.getValue('smart_settings.status');
    const inviteStatus = this.getValue('smart_settings.invite_status');
    let res;

    if (status === SMART_USER_STATUSES.LIVE) {
      res = {
        variant: 'active',
        name: Vue.prototype.$vDict('users.onboarding_status_live.text'),
      };
    } else if (inviteStatus === INVITE_STATUSES.NOT_SENT) {
      res = {
        variant: 'not-invited',
        name: Vue.prototype.$vDict('users.onboarding_status_not_invited.text'),
      };
    } else if (inviteStatus === INVITE_STATUSES.SENT) {
      res = {
        variant: 'invited',
        name: Vue.prototype.$vDict('users.onboarding_status_invited.text'),
      };
    } else if (inviteStatus === INVITE_STATUSES.SENDING) {
      res = {
        variant: 'invited',
        name: Vue.prototype.$vDict('users.onboarding_status_inviting.text'),
      };
    }

    return res;
  }

  isShowSendInvite() {
    if (!this.hasActiveSubscription()) {
      return false;
    }

    return this.user &&
      !this.user.getValue('is_suspended') &&
      this.getValue('smart_settings.status') !== SMART_USER_STATUSES.LIVE;
  }

  isActive() {
    const isActive = this.getValue('smart_settings.invite_status') === INVITE_STATUSES.SENT ||
      this.getValue('smart_settings.status') === SMART_USER_STATUSES.LIVE;

    return isActive && this.hasActiveSubscription();
  }

  getActions(isShowUserActions = false) {
    const allActions = this.selfClass.getAllActions();

    return allActions.filter(action => {
      if (action.type === 'user') {
        const userActions = isShowUserActions && this.user ? this.user.getActions() : [];
        return userActions.some(userAction => userAction.key === action.key);
      }

      let res = true;

      switch (action.key) {
        case 'send_magic_link':
          res = !this.client.isSSO() &&
            !this.user.getValue('is_dummy') &&
            !this.user.getValue('is_suspended') &&
            this.isActive();
          break;
        case 'view_client':
          res = router.currentRoute.name !== Client.routes.view ||
            this.client?.id.toString() !== router.currentRoute.params?.id.toString();
          break;
        case 'edit_client':
          res = !isShowUserActions && !!this.user && !!this.client;
          break;
        case 'send_invite':
          res = this.isShowSendInvite();
          break;
        case 'copy_invite_link':
          res = this.getValue('smart_settings.invite_status') !== INVITE_STATUSES.NOT_SENT &&
            this.isShowSendInvite();
          break;
        case 'view_stats':
          res = store.getters['Account/hasComponent']('usage_stats_user');
          break;
        case 'transfer_data':
          res = store.getters['Account/hasComponent']('user_transfer_task_button');
          break;
        default:
      }

      return res;
    });
  }

  async doAction(action) {
    if (action.type === 'user') {
      await this.user?.doAction(action);
      return;
    }

    switch (action.key) {
      case 'send_magic_link':
        await this.sendMagicLink();
        break;
      case 'view_client':
        this.client.goToView();
        break;
      case 'edit_client':
        router.push({
          name: 'SupportClientUserEditor',
          params: {
            id: this.user.id,
            clientId: this.client.id,
          },
          query: {
            from: router.currentRoute.fullPath,
          },
        });
        break;
      case 'send_invite':
        await this.sendInvite();
        break;
      case 'copy_invite_link':
        await this.copyInviteLink();
        break;
      case 'view_stats':
        router.push({
          name: 'UsageStatisticsUser',
          params: {
            id: this.user?.id,
          },
          query: {
            client_id: this.client?.id,
          },
        });
        break;
      case 'transfer_data':
        router.push({
          name: 'SupportEditorUserTransferTaskNew',
          query: {
            to_user_id: this.id,
            from: router.currentRoute.fullPath,
          },
        });
        break;
      default:
        await super.doAction(action);
    }
  }

  async checkInviteStatus(attempt = 0) {
    const clientUser = await this.selfFetch();
    const inviteStatus = clientUser?.smart_settings?.invite_status;

    if (clientUser && inviteStatus && inviteStatus !== this.getValue('smart_settings.invite_status')) {
      await this.patchData(clientUser);
    }

    if (inviteStatus === INVITE_STATUSES.SENDING && attempt > 0) {
      setTimeout(async () => this.checkInviteStatus((attempt - 1)), 5000);
    }
  }

  async sendInvite() {
    try {
      await inviteUser(this.client.id, this.user.id);
      Vue.prototype.$notifToastr('success', Vue.prototype.$vDict('users.send_invite_success.text'));
      await this.checkInviteStatus(3);
    } catch (error) {
      console.log(error);
    }
  }

  async copyInviteLink() {
    let basePath = `http://${window.location.hostname}`;

    if (window.envMode === ENV_MODES.LOCALHOST) {
      const { port } = window.location;

      if (port) {
        basePath += `:${port}`;
      }
    }

    let inviteUrl;

    if (this.client.isSSO()) {
      inviteUrl = new URL(`${basePath}/invite-sso`);
      inviteUrl.searchParams.set('set_language_id', this.getValue('smart_settings.language_id'));
      inviteUrl.searchParams.set('set_client_id', this.client?.id);
      inviteUrl.searchParams.set('userEmail', this.user.getValue('email'));
    } else {
      inviteUrl = new URL(`${basePath}/invite`);

      try {
        const response = await getToken(this.user.id, USER_TOKEN_TYPES.INVITE, {
          issue: 1,
        });

        if (response?.data) {
          inviteUrl.searchParams.set('token', response.data.token);
          inviteUrl.searchParams.set('set_client_id', this.client.id);
        }
      } catch (error) {
        if (error.code === SERVER_ERROR_CODES.NOT_FOUND) {
          Vue.prototype.$notifToastr('error', Vue.prototype.$vDict('users.copy_invite_link_error_token.text'));
        }
      }
    }

    Vue.prototype.$copyText(inviteUrl.href).then(() => {
      Vue.prototype.$notifToastr('success', Vue.prototype.$vDict('users.copy_invite_link_success.text'));
    }, () => {
      Vue.prototype.$notifToastr('error', 'Unexpected error in copy plugin.');
    });
  }

  getSubscriptions() {
    const subscriptions = this.client?.subscriptions?.length ?
      this.client.subscriptions :
      this.subscriptions;
    return subscriptions.filter(subscription => subscription.getValue('client_id') === this.client.id);
  }

  hasActiveSubscription(_subscriptions) {
    const subscriptions = _subscriptions || this.getSubscriptions();
    return subscriptions.some(subscription => subscription.isActiveOnUser());
  }

  async selfFetch() {
    let data;

    try {
      const response = await ClientUser.loadItemFunc(this.client.id, this.user.id);
      data = response?.data || {};
    } catch (e) {
      console.log(e);
    }

    return data;
  }

  hasSSOSetupFields() {
    return store.getters['Account/hasComponent']('sso_setup') &&
      this.client?.isSSO();
  }

  async sendMagicLink() {
    const authData = {
      email: this.user.getValue('email'),
      token_type: USER_TOKEN_TYPES.MAGIC_LINK,
      client_id: this.client.id,
    };

    try {
      await token(authData);
      Vue.prototype.$notifToastr('success', Vue.prototype.$vDict('users.send_magic_link_success.text'));
    } catch (error) {
      console.log(error);
    }
  }
}
