/*
  TendersList class
 */

import Vue from "vue";
import CRMEntityList from "@/core/classes/list/CRMEntityList";
import {
  USER_TENDER_STATUSES,
} from "@/config/enums";
import {
  deleteTenders,
  getTenders as getUserTenders
} from "@/api/repositories/salesRepository";
import { requiredFields, fieldsSections, printSettingsFields } from "@/entities/tender/fields";
import { getTendersListsConfig } from "@/entities/tender/config";
import store from "@/store";
import { getItemsId } from "@/shared/utils";
import {
  Contact,
  Project,
  Company,
} from "@/entities";
import { BaseTender } from "@/entities/tender/BaseTender";

const TendersTableView = () => import('./views/TendersTableView');
const TenderCardView = () => import('./views/TenderCardView');

const sortLookups = () => [
  { sortKey: 'tender_subject_id', lookupKey: 'client.tender_subjects' },
  { sortKey: 'tender_type_id', lookupKey: 'client.tender_types' },
  { sortKey: 'tender_status_id', lookupKey: 'client.tender_statuses' },
  { sortKey: 'company_role_id', lookupKey: 'client.company_roles' },
  { sortKey: 'planning_stage_id', lookupKey: 'client.planning_stages' },
];

export class BaseTendersList extends CRMEntityList {
  static urlStatusKey = 'status';
  static statusIdWithNewItems = USER_TENDER_STATUSES.NEW;
  static entityClass = BaseTender;
  static isStaticNavigation = true;

  constructor(listTypeId) {
    super(listTypeId, getTendersListsConfig);

    this.filterKeysWithScore = ['keywords'];

    // variables
    this.sortLookups = sortLookups();

    // for print
    this.printAdditionalFieldset = ['cat_dev_type'];
    this.printFieldsBlacklist = [
      // 'name',
      'status',
      'notes',
      'tags',
      // 'email',
    ];

    // fields
    this.getPrintSettingsFields = printSettingsFields;
    this.getRequiredFields = requiredFields;
    this.getFieldsSections = fieldsSections;

    // view components
    this.tableView = TendersTableView;
    this.cardView = TenderCardView;

    //
    this.selfClass = BaseTendersList;
  }

  getSubtitleForClearFolderForm() {
    return Vue.prototype.$vDict('entity_list.clear_folder_tenders_subtitle.text');
  }

  getWaitForItemsText() {
    return Vue.prototype.$vDict('tenders.tenders_in_way.text');
  }

  getEmptyListTitle() {
    return this.isFiltersFilled ?
      Vue.prototype.$vDict('entity_list.tenders_list_empty_by_search.text') :
      Vue.prototype.$vDict('entity_list.tenders_list_empty.text');
  }

  getContentStats() {
    return store.getters['Account/getUserContentStats']('user_tender');
  }

  loadDatasetByQueryFields(items = [], queryFields = []) {
    const ids = {
      regions: queryFields.includes('region_id') ? getItemsId(items, 'region_id') : [],
      districts: queryFields.includes('district_id') ? getItemsId(items, 'district_id') : [],
    };

    this.loadGeographyDataset(ids);

    if (queryFields.includes('responsible_ids') || queryFields.includes('users')) {
      store.dispatch('UserClient/fetchUsers');
    }

    if (queryFields.includes('project_id')) {
      this.loadDatasetEntities(Project, {
        ids: getItemsId(items, 'project_id'),
        fields: ['id', 'title', 'subtitle', 'description', 'what_happens'],
      });
    }

    if (queryFields.includes('company_id')) {
      this.loadDatasetEntities(Company, {
        ids: getItemsId(items, 'company_id'),
        fields: ['id', 'name'],
      });
    }

    if (queryFields.includes('contact_id')) {
      this.loadDatasetEntities(Contact, {
        ids: getItemsId(items, 'contact_id'),
        fields: ['id', 'first_name', 'last_name', 'communications'],
      });
    }
  }
}
