/*
  SavedSearchNotification class
 */

import store from '@/store';
import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES, LIST_TYPES } from '@/config/enums';
import { getSavedSearchNotifications} from "@/api/repositories/searchesRepository";
import { getEntityListConfigs, User } from "@/entities";
import { formatDateTime } from "@/shared/utils";
import router from "@/router";
import { EntityListFactory } from "@/entities/EntityListFactory";
import Vue from "vue";

export class SavedSearchNotification extends BaseEntity {
  static entityKey = 'saved_search_notification';
  static loadAllItemsFunc = getSavedSearchNotifications;
  static datasetKey = 'savedSearchNotifications';
  static entityTypeId = ENTITY_TYPES.SAVED_SEARCH_NOTIFICATION;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('saved_search_notifs.ssn_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = SavedSearchNotification;
  }

  static getAllActions() {
    return [
      {
        key: 'go_to_ssn',
        dataCy: 'go-to-ssn',
        icon: 'logout',
      },
    ];
  }

  static getListTypes() {
    const elConfigs = getEntityListConfigs();
    const listTypes = _.invert(LIST_TYPES);
    const res = [];

    Object.entries(elConfigs).forEach(([id, config]) => {
      if (config?.showSsn) {
        const key = listTypes[id];

        res.push({
          id,
          name: Vue.prototype.$vDict(`enums.list_type_${key.toLowerCase()}.text`),
          hasAccess: config.hasAccess,
        });
      }
    });

    return res;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'created_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'list_type_id':
        value = this.getListType();
        res = value.name;
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'user_id':
        res = this.getUser(dataset);
        break;
      case 'client_id':
        res = this.getClient(dataset);
        break;
      default:
    }

    return res;
  }

  getActions(dataset = {}) {
    const actions = this.selfClass.getAllActions();

    return actions.filter(action => {
      let res = true;

      switch (action.key) {
        case 'go_to_ssn':
          if (store.getters['Account/userId'] !== this.getValue('user_id')) {
            const userData = this.getUser(dataset);

            if (userData) {
              const user = new User(userData);
              res = user.isShowLoginAs();
            }
          }
          break;
        default:
      }

      return res;
    });
  }

  getSSNLinkData() {
    const listTypeId = this.getValue('list_type_id');
    const entityListClass = EntityListFactory.getClass(listTypeId);
    const routes = entityListClass?.entityClass?.routes || {};

    let urlPath = routes.list ? router.resolve({
      name: routes.list,
    }).href : '';

    if (!urlPath) {
      return;
    }

    const accessCode = this.getValue('access_code');
    const ssnUrl = `${urlPath}?ssn=${accessCode}`.replace(/^\//,'');
    const urlParams = urlPath && accessCode ? {
      destination: encodeURI(ssnUrl)
    } : {};

    if (store.getters['Account/userId'] === this.getValue('user_id')) {
      return {
        type: 'router',
        data: {
          path: '/' + ssnUrl,
        },
      };
    }

    const userId = this.getValue('user_id');
    const user = this.getDatasetItem('users', userId);
    const userData = new User(user || { id: userId });

    return {
      type: 'login_as_user',
      userData,
      urlParams,
    };
  }
}
