/*
  CustomFieldMetricsList class
 */

import EntityList from "@/core/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import CustomFieldSelectionMetricsTableView from "@/entities/custom-field-selection-metric/views/CustomFieldSelectionMetricsTableView";
import Vue from "vue";
import { getCustomFieldSelectionMetricsListsConfig } from "@/entities/custom-field-selection-metric/config";
import { requiredFields } from "@/entities/custom-field-selection-metric/fields";
import { CustomFieldSelectionMetric } from "@/entities";

export class CustomFieldSelectionMetricsList extends EntityList {
  static entityClass = CustomFieldSelectionMetric;

  constructor(listTypeId) {
    super(listTypeId, getCustomFieldSelectionMetricsListsConfig);

    this.requiredFields = requiredFields();
    this.tableView = CustomFieldSelectionMetricsTableView;

    this.selfClass = CustomFieldSelectionMetricsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'key',
        dataCy: 'key',
        name: Vue.prototype.$vDict('custom_field_metrics.filter_key.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('custom_field_metrics.filter_key_placeholder.text'),
        },
      },
    ];
  }
}
