/*
  MyranSubscriptionVersion class
 */

import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getSubscriptionVersions } from '@/api/repositories/myranRepository';

export class MyranSubscriptionVersion extends BaseEntity {
  static entityKey = 'myran_subscription_version';
  static datasetKey = 'myranSubscriptionVersions';
  static entityTypeId = ENTITY_TYPES.MYRAN_SUBSCRIPTION_VERSION;

  // api
  static loadAllItemsFunc = getSubscriptionVersions;
  // static getEntityPluralTypeText() {
  //   return Vue.prototype.$vDict('user_files.plural_files_text.text');
  // }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = MyranSubscriptionVersion;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'source_type_id':
        value = this.getItemFromEnum('SOURCE_TYPES', fieldValue);
        res = value?.name || '';
        break;
      case 'link_user_profiles':
        res = this.getValue('id');
        break;
      case 'subscription_type_id':
        value = this.getSubscriptionType();
        res = value?.name || '';
        break;
      case 'created_at':
        res = fieldValue;
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getSubscriptionType() {
    return this.getItemFromEnum('SUBSCRIPTION_TYPES', this.getValue('subscription_type_id'));
  }
}
