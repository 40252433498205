/*
  VortalCompany class
 */

import Vue from 'vue';
import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getCompanies, patchCompany } from "@/api/repositories/vortalConnectRepository";
import { formatDateTimeNoWrap} from "@/shared/utils";

export class VortalCompany extends BaseEntity {
  static entityKey = 'vortal_company';
  static datasetKey = 'vortalCompanies';
  static entityTypeId = ENTITY_TYPES.VORTAL_COMPANY;
  static routes = {
    edit: 'VortalConnectEditorCompany',
    list: 'VortalConnectCompanies',
  };

  // api
  static loadAllItemsFunc = getCompanies;
  static patchItemFunc = patchCompany;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('vortal_connect_companies.companies_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = VortalCompany;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: Vue.prototype.$vDict('vortal_connect_companies.company_edit_button.text'),
        icon: 'edit',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'country_id':
        res = Vue.prototype.$lFind('global.countries', { id: fieldValue, prop: 'name' });
        break;
      case 'vortal_company_identifier_type':
        value = this.getItemFromEnum('VORTAL_COMPANY_IDENTIFIER_TYPES', fieldValue);
        res = value?.name || '';
        break;
      case 'created_at':
      case 'modified_at':
        res = fieldValue ? formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }
}
