/*
ErrorMessagesList class
*/

import Vue from "vue";
import EntityList from "@/core/classes/list/EntityList";
import { ENTITY_TYPES } from "@/config/enums";
import ErrorMessagesTableView from "@/entities/error-messages/views/ErrorMessagesTableView";
import { getErrorMessagesListsConfig } from "@/entities/error-messages/config";
import { getErrorMessages } from "@/api/repositories/dictionariesRepository";
import { requiredFields } from "@/entities/error-messages/fields";
import { ErrorMessage } from "@/entities";

export class ErrorMessagesList extends EntityList {
  static entityClass = ErrorMessage;

  constructor(listTypeId) {
    super(listTypeId, getErrorMessagesListsConfig);

    this.tableView = ErrorMessagesTableView;

    this.selfClass = ErrorMessagesList;
    this.getRequiredFields = requiredFields;
  }
}
