/*
  MyranSuspendedUser class
 */

import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getSuspendedUsers } from '@/api/repositories/myranRepository';

export class MyranSuspendedUser extends BaseEntity {
  static entityKey = 'myran_suspended_user';
  static datasetKey = 'myranSuspendedUsers';
  static entityTypeId = ENTITY_TYPES.MYRAN_SUSPENDED_USER;

  // api
  static loadAllItemsFunc = getSuspendedUsers;
  static getEntityPluralTypeText() {
    // return Vue.prototype.$vDict('user_files.plural_files_text.text');
    return 'Users';
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = MyranSuspendedUser;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
        res = fieldValue;
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'user_id':
        res = this.getUser(dataset);
        break;
      default:
    }

    return res;
  }
}
