/*
  PMCustomFieldSelectionsList class
 */

import Vue from 'vue';
import EntityList from "@/core/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
} from "@/config/enums";
import CustomFieldSelectionsTableView from "@/entities/parabyg-migration/pm-custom-field-selection/views/CustomFieldSelectionsTableView";
import { getPMCustomFieldSelectionListsConfig } from "@/entities/parabyg-migration/pm-custom-field-selection/config";
import { getPMCustomFieldSelections } from "@/api/repositories/parabygMigrationRepository";
import { requiredFields } from "@/entities/parabyg-migration/pm-custom-field-selection/fields";
import { PMCustomFieldSelection } from "@/entities";
// import { Client } from "@/entities";

export class PMCustomFieldSelectionsList extends EntityList {
  static entityClass = PMCustomFieldSelection;

  constructor(listTypeId) {
    super(listTypeId, getPMCustomFieldSelectionListsConfig);

    this.getRequiredFields = requiredFields;
    this.tableView = CustomFieldSelectionsTableView;
    // this.settingsForLoadingDatasets = [
    //   {
    //     datasetKey: Client.datasetKey,
    //     loadFunc: Client.loadAllItemsFunc,
    //     fieldKeys: ['client_id'],
    //   },
    // ];

    this.selfClass = PMCustomFieldSelectionsList;
  }

  getListFiltersSetting() {
    const rbOptions = [
      { id: 0, name: Vue.prototype.$vDict('global.text_no.text') },
      { id: 1, name: Vue.prototype.$vDict('global.text_yes.text') },
      { id: -1, name: Vue.prototype.$vDict('parabyg_migration.filter_option_for_null_value.text') }
    ];

    return [
      {
        key: 'id',
        dataCy: 'id',
        name: Vue.prototype.$vDict('entity_list.field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
        },
      },
      {
        key: 'pm_client_version_id',
        dataCy: 'pm-client-version-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_client_version_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'pm_custom_field_id',
        dataCy: 'pm-custom-field-id',
        name: Vue.prototype.$vDict('parabyg_migration.field_pm_custom_field_id.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('entity_list.filter_id_input_placeholder.text'),
          validationType: 'number',
        },
      },
      {
        key: 'value',
        dataCy: 'value',
        name: Vue.prototype.$vDict('parabyg_migration.field_value.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_value_input_placeholder.text'),
        },
      },
      {
        key: 'custom_field_selection_key',
        dataCy: 'custom_field_selection_key',
        name: Vue.prototype.$vDict('parabyg_migration.field_custom_field_selection_key.text'),
        filterType: SEARCH_FILTER_TYPES.INPUT,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('parabyg_migration.field_custom_field_selection_key_input_placeholder.text'),
        },
      },
      {
        key: 'is_valid',
        dataCy: 'is-valid',
        name: Vue.prototype.$vDict('parabyg_migration.field_is_valid.text'),
        filterType: SEARCH_FILTER_TYPES.SMO_RADIO_BUTTONS,
        filterAttrs: {
          showEmptyOption: true,
          options: rbOptions,
        },
      },
    ];
  }
}
