/*
  Module class
 */

import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import {
  getModules,
  putModule,
  postModule,
  getModule, deleteModule,
} from "@/api/repositories/systemRepository";
import Vue from "vue";

export class Module extends BaseEntity {
  static entityKey = 'module';
  static datasetKey = 'modules';
  static entityTypeId = ENTITY_TYPES.MODULE;
  static routes = {
    edit: 'SystemEditorModule',
    create: 'SystemEditorModuleNew',
  };

  static loadAllItemsFunc = getModules;
  static putItemFunc = putModule;
  static postItemFunc = postModule;
  static loadItemFunc = getModule;
  static deleteItemFunc = deleteModule;
  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('system_modules.modules_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = Module;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  getFieldValue(fieldKey) {
    let res;
    let value;

    switch (fieldKey) {
      case 'module_visibility':
        value = this.getModuleVisibility();
        res = value?.name || '';
        break;
      case 'components':
        res = this.getValue(fieldKey, []);
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getModuleVisibility() {
    return this.getItemFromEnum('MODULE_VISIBILITY', this.getValue('module_visibility'));
  }
}
