/*
  CRMEntityList list
 */

import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import EntityList from "@/core/classes/list/EntityList";
import {
  ENTITY_LIST_VIEWS,
  CUSTOM_FIELD_TYPES,
  ACTIVITY_STATUSES
} from '@/config/enums';
import { convertSettingsToSearch } from "@/shared/search";
import { getNoteEntities, getActivityEntities } from "@/api/repositories/salesRepository";
import { IndexedArray } from "@/shared/proxies";
import { mergeQueryParams } from "@/shared/utils";
import { User } from '@/entities';

export default class CRMEntityList extends EntityList {
  static urlStatusKey = 'status';

  constructor(...args) {
    super(...args);

    // map
    this.mapData = {
      items: [],
      // currentItem: null,
      bounds: null,
      savedParams: {
        mapSize: null,
        bounds: null,
      },
    };
    this.mapFields = ['id', 'location'];

    //
    this.ssnCode = router?.currentRoute?.query?.ssn;
  }

  async getMainInstance() {
    if (this.statusId !== undefined && this.statusId !== 'all') {
      const elInstance = new this.selfClass(this.listTypeId);

      elInstance.setStatus('all');
      await elInstance.initListData();

      return elInstance;
    }

    return this;
  }

  resetCurrentData() {
    this.items = [];
    this.total = 0;
    this.lockedTotal = 0;
    this.allTotal = 0;
    this.aggs = {};
    this.selectedItems = [];
    // this.currentPage = 1;
    // this.viewMode = ENTITY_LIST_VIEWS.LIST;
    this.permanentQueryData = {};
    this.setCurrentPage(1);
  }

  setStatus(statusId) {
    this.statusId = statusId;

    // if (this.config.isSavePosition) {
    //   store.commit('SET_LIST_VALUE', {
    //     listTypeId: this.listTypeId,
    //     key: 'statusId',
    //     value: statusId,
    //   });
    // }
  }

  changeStatus(statusId) {
    if (this.listDataInited) {
      this.resetCurrentData();
    }

    this.setStatus(statusId);
    this.initDefaultValues();
    this.initPermanentFilter();
    this.initCurrentSort();
  }

  getMapData() {
    const { entityClass } = this.selfClass;
    const mapDataParams = entityClass.getMapParams();
    const {
      items,
      ...restMapData
    } = this.mapData;
    const mapItems = items.map(itemData => {
      const entity = new entityClass(itemData);
      return entity.getMapData();
    });

    return {
      items: mapItems,
      categories: [],
      ...restMapData,
      ...mapDataParams,
    };
  }

  initPerPage() {
    if (this.config.isMain) {
      this.perPage = this.printing ?
        Number(process.env.VUE_APP_PRINT_MAX_LIMIT) :
        Number(process.env.VUE_APP_ITEMS_PER_PAGE);
    } else {
      this.perPage = this.printing ?
        Number(process.env.VUE_APP_PRINT_MAX_LIMIT_INNER) :
        Number(process.env.VUE_APP_ITEMS_PER_SUBPAGE);
    }
  }

  initCustomFields() {
    // init custom fields
    const customFieldsets = store.getters.getCustomFieldsets({
      entityTypeId: this.selfClass.entityClass.entityTypeId,
    });

    this.customFields = customFieldsets.reduce((acum, fieldset) => {
      return acum.concat(fieldset.fields);
    }, []);

    // add custom fields to fieldsSections
    const fieldsSection = this.fieldsSections.find(section => section.key === 'custom_fields');

    if (fieldsSection) {
      fieldsSection.fields = this.customFields.map(field => {
        const fieldKey = `custom_fields.CF_${field.id}`;
        const sortable = ![
          CUSTOM_FIELD_TYPES.MULTIPLE_SELECT,
          CUSTOM_FIELD_TYPES.EMAIL,
          CUSTOM_FIELD_TYPES.PHONE_NUMBER,
          CUSTOM_FIELD_TYPES.CHECKBOX,
          CUSTOM_FIELD_TYPES.ATTACHMENT,
        ].includes(field.custom_field_type_id);
        const wideInfo = [
          CUSTOM_FIELD_TYPES.ATTACHMENT,
        ].includes(field.custom_field_type_id);

        return {
          key: fieldKey,
          label: field.name,
          dataCy: field.name.toLowerCase().replace(/ /g, '-'),
          section_id: -1,
          fieldset: [fieldKey],
          data: field,
          sortable,
          wideInfo,
        };
      });
    }
  }

  initPermanentFilter() {
    const filter = this.permanentQueryData.filter || {};
    const { statusFilterKey, statusNewId } = this.selfClass.entityClass;

    if (this.statusId === 'all') {
      filter.users = {
        nested_or: [
          {
            'users.user_id': {
              not_in: [store.getters['Account/userId']],
            },
            'users.user_suspended': {
              eq: 0,
            },
            [statusFilterKey]: {
              not_in: [statusNewId],
            },
          },
          {
            'users.user_id': {
              in: [store.getters['Account/userId']],
            },
          },
        ],
      };
    } else if (this.statusId !== undefined) {
      filter.users = {
        nested_or: [
          {
            'users.user_id': {
              not_in: [store.getters['Account/userId']],
            },
            'users.user_suspended': {
              eq: 0,
            },
          },
          {
            'users.user_id': {
              in: [store.getters['Account/userId']],
            },
          },
        ],
      };

      Object.assign(filter, {
        [statusFilterKey]: {
          in: [this.statusId],
        },
      });
    }

    Vue.set(this.permanentQueryData, 'filter', filter);
  }

  async onChangeEntityHandler(entity) {
    if (!this.selfClass.isUserSection) {
      return;
    }

    if (this.selfClass.getEntityProperty('hasContentStats') &&
      !entity.getValue('viewed')) {
      this.fetchContentStats();
    }

    const entityData = await this.refetchItem(entity.id);

    if (entityData) {
      await entity.patchData(entityData, true);
    } else {
      await this.deleteItem(entity.id);
    }
  }

  async deleteItem(entityId) {
    if (this.viewMode === ENTITY_LIST_VIEWS.MAP) {
      this.mapData.items = this.mapData.items
        .filter(item => item.id !== entityId)
        .map(item => {
          if (!item.hasOwnProperty('group')) {
            return item;
          }

          return {
            ...item,
            group: item['group'].filter(el => el.id !== entityId),
          };
        });
      return;
    }

    await super.deleteItem(entityId);
  }

  async onChangeEntity(entity, patchData = {}) {
    const itemIndex = _.findIndex(this.items, el => el.id === entity.id);

    if (itemIndex === -1) {
      return;
    }

    await this.onChangeEntityHandler(entity, patchData, itemIndex);
    entity.prepareFieldsData(this.userFieldset, this.listTypeId);
  }

  async loadResponsibleValue(items = []) {
    if (this.selfClass.isUserSection) {
      return;
    }

    try {
      const response = await this.selfClass.entityClass.loadUserItemsFunc({
        fields: ['id', 'responsible_ids'],
        filter: {
          id: {
            in: items.map(item => item.id),
          },
        },
        limit: items.length,
        context: 'client',
      });
      const userItems = new IndexedArray(response?.data || []);

      items.forEach(item => {
        const userItem = userItems.findById(item.id);
        item.setValue('responsible_ids', userItem?.responsible_ids || []);
      });
    } catch (e) {}
  }

  async loadAdditionalValues(items = [], queryFieldKeys = [], currentFields = []) {
    if (currentFields.findById('responsible')) {
      await this.loadResponsibleValue(items);

      const responsibleIds = items.reduce((acum, item) => _.union(acum, item.getValue('responsible_ids', [])), []);
      const unknownUserIds = store.getters['UserClient/getUnknownUserIds'](responsibleIds);

      if (unknownUserIds.length) {
        this.loadDatasetEntities(User, {
          ids: unknownUserIds,
          fields: ['id', 'name'],
        });
      }
    }
  }

  async loadDatasets(items, queryFields = []) {
    if (queryFields.includes('tags')) {
      store.dispatch('UserTags/fetchUserTags');
    }

    this.loadDatasetByQueryFields(items, queryFields);
  }

  async loadAllStatusesForItems(items = []) {
    if (!items.length) {
      return;
    }

    await Promise.all([
      this.loadItemsStatuses(items),
      this.loadItemsNoteStatuses(items),
      this.loadItemsActivityStatuses(items),
    ]);
  }

  async prepareItems(items = [], queryFields) {
    let preparedItems = await super.prepareItems(items, queryFields);
    const requests = [
      this.loadAdditionalValues(preparedItems, queryFields, this.currentFields),
    ];

    if (!this.printing) {
      requests.push(this.loadAllStatusesForItems(preparedItems));
    }

    await Promise.all(requests);

    return preparedItems;
  }

  async loadItemsStatuses(items = []) {
    if (this.selfClass.isUserSection) {
      return;
    }

    const itemsIds = items.map(el => el.id);
    const query = {
      limit: itemsIds.length,
      fields: ['id'],
      filter: {
        id: {
          in: itemsIds,
        },
      },
    };
    const { statusKey, loadUserItemsFunc } = this.selfClass.entityClass;

    if (statusKey) {
      query.fields.push(statusKey);
    }

    try {
      const response = await loadUserItemsFunc(query);
      const statuses = response?.data || [];

      statuses.forEach(status => {
        const statusValue = status[statusKey];
        const entity = items.findById(status.id);

        if (entity) {
          entity.setValue(statusKey, statusValue);
        }
      });
    } catch (error) {}
  }

  async loadItemsNoteStatuses(items = []) {
    const itemsIds = items.map(el => el.id);
    const query = {
      filter: {
        entity_type_id: {
          eq: this.selfClass.entityClass.entityTypeId,
        },
        entity_id: {
          in: itemsIds,
        },
      },
    };

    try {
      const response = await getNoteEntities(query);
      const entityIds = response?.data || [];

      entityIds.forEach(entityId => {
        const entity = items.findById(entityId);

        if (entity) {
          entity.setValue('note_status', true);
        }
      });
    } catch (error) {}
  }

  async loadItemsActivityStatuses(items = []) {
    const itemsIds = items.map(el => el.id);
    const query = {
      filter: {
        entity_type_id: {
          eq: this.selfClass.entityClass.entityTypeId,
        },
        entity_id: {
          in: itemsIds,
        },
      },
      utc_offset: '+00:00',
    };

    try {
      const response = await getActivityEntities(query);
      const statusesGroups = _.groupBy(response?.data || [], 'entity_id');

      Object.entries(statusesGroups).forEach(([key, statuses]) => {
        const entityId = Number(key);
        const statusValue = statuses.reduce((resStatusId, statusData) => {
          const statusId = statusData.activity_status;
          const resPriority = _.get(ACTIVITY_STATUSES.properties, `${resStatusId}.priority`) || 0;
          const priority = _.get(ACTIVITY_STATUSES.properties, `${statusId}.priority`) || 0;
          return priority < resPriority || !resStatusId ? statusId : resStatusId;
        }, 0);
        const entity = items.findById(entityId);

        if (entity) {
          entity.setValue('activity_status', statusValue);
        }
      });
    } catch (error) {}
  }

  async loadMapItems(mapSize = {}, bounds = null) {
    if (!mapSize) {
      return;
    }

    const { width, height } = mapSize;

    this.mapData.savedParams = {
      mapSize,
      bounds,
    };

    if (!width || !height) {
      return;
    }

    const queryFilters = this.getQueryFilters();
    const query = {
      fields: this.mapFields,
      offset: 0,
      geo_clustering: 1,
      viewport_w: width,
      viewport_h: height,
      ...queryFilters,
    };
    const loadItemsFunc = this.selfClass.getLoadItemsFunc();

    if (bounds) {
      Object.assign(query, {
        bb_tl_lat: bounds.top_left.lat,
        bb_tl_lon: bounds.top_left.lon,
        bb_br_lat: bounds.bottom_right.lat,
        bb_br_lon: bounds.bottom_right.lon,
      });
    }

    this.isLoadingMapItems = true;
    this.loadMetaData();
    this.initSearchFilled();

    try {
      const response = await loadItemsFunc(query);

      this.mapData.items = response?.data || [];
      this.mapData.bounds = response?.meta?.bounding_box || null;
    } catch (error) {}

    this.isLoadingMapItems = false;
  }

  async loadMapPlaceItems(entityIds = []) {
    const query = {
      sort: this.getQuerySort(),
      fields: this.getQueryFields(),
      filter: { id: { in: entityIds } },
      limit: entityIds.length,
    };
    const loadItemsFunc = this.selfClass.getLoadItemsFunc();

    // this.mapData.currentItem = null;
    this.items = [];
    this.isLoadingMapItems = true;

    try {
      const response = await loadItemsFunc(query);
      const items = response?.data || [];

      this.items = await this.prepareItems(items, query.fields);
      this.loadDatasets(items, query.fields);
    } catch (error) {}

    this.isLoadingMapItems = false;
  }

  async onErrorLoadingItems() {
    if (this.ssnCode) {
      this.ssnCode = null;
      store.commit('SET_SHOW_SSN_REMOVED_MESSAGE', true);
      return this.loadListItems();
    }
  }

  async onSuccessLoadingItems() {
    const selectedSearch = store.getters.getSelectedSearch(this.listTypeId);

    if (this.ssn) {
      const searchFilter = convertSettingsToSearch(this.ssn.settings || {});

      store.commit('UPDATE_USER_SELECTED_SEARCH', {
        listTypeId: this.listTypeId,
        data: {
          searchId: this.ssn.search_id,
          ssn: this.ssn.access_code,
        },
      });
      this.setSearchFilter(searchFilter);
      this.initSearchFilled();
      this.ssnCode = null;
    } else if (selectedSearch.searchId && !selectedSearch.ssn) {
      store.commit('UPDATE_USER_SELECTED_SEARCH', {
        listTypeId: this.listTypeId,
        data: {
          ssnAllTotal: meta.total,
        },
      });
    }
  }

  getPermanentQueryData() {
    let query = super.getPermanentQueryData();

    if (this.config.hasSavedByFilter) {
      query = mergeQueryParams(query, {
        filter: this.getPermanentSavedByFilter(),
      });
    }

    return query;
  }

  getPermanentSavedByFilter() {
    const userIds = store.getters['UserClient/users'].map(el => el.id);
    let filter;

    if (this.statusId !== undefined) {
      const nestedOr = this.getNestedOrFiltersByUsers(userIds);

      if (nestedOr.length > 0) {
        filter = {
          users: {
            nested_or: nestedOr,
          },
        };
      }
    } else {
      filter = {
        'users.user_id': {
          in: userIds,
        },
      };
    }

    return filter;
  }

  getNestedOrFiltersByUsers(userIds = [], statusIds) {
    const { statusFilterKey, statusNewId } = this.selfClass.entityClass;
    const nestedOr = [];

    userIds.forEach(userId => {
      const nestedFilter = {
        'users.user_id': {
          in: [userId],
        },
      };

      if (statusIds) {
        nestedFilter[statusFilterKey] = {
          in: statusIds,
        };
      } else if (userId !== store.getters['Account/userId']) {
        nestedFilter[statusFilterKey] = {
          not_in: [statusNewId],
        };
      }

      if (userId !== store.getters['Account/userId']) {
        nestedFilter['users.user_suspended'] = {
          eq: 0,
        };
      }

      nestedOr.push(nestedFilter);
    });

    return nestedOr;
  }

  initUsersNestedFilter(searchFilter = {}) {
    const { statusFilterKey } = this.selfClass.entityClass;
    const usersNestedFilter = {
      nested_or: [],
    };
    const userIds = searchFilter['users.user_id'];
    const statusIds = searchFilter[statusFilterKey];

    if (userIds) {
      usersNestedFilter.nested_or = this.getNestedOrFiltersByUsers(userIds, statusIds);
      delete searchFilter['users.user_id'];
    } else if (statusIds) {
      usersNestedFilter.nested_or.push({
        [statusFilterKey]: {
          in: statusIds,
        },
        'users.user_suspended': {
          eq: 0,
        },
      });
    }

    if (searchFilter.hasOwnProperty(statusFilterKey)) {
      delete searchFilter[statusFilterKey];
    }

    return usersNestedFilter.nested_or.length > 0 ? usersNestedFilter : null;
  }

  async bulkStatusChange(ids = [], statusId) {
    const { entityClass } = this.selfClass;
    const { statusKey, idsKey } = entityClass;

    if (!entityClass.putItemsFunc ||
      !idsKey) {
      return;
    }

    const query = {
      [idsKey]: ids,
      // viewed: 1,
    };

    if (statusKey && statusId) {
      query[statusKey] = statusId;
    }

    return await entityClass.putItemsFunc(query);
  }

  async bulkDelete(ids = []) {
    const { entityClass } = this.selfClass;
    const { statusKey, idsKey } = entityClass;

    if (!entityClass.deleteItemsFunc || !idsKey) {
      return;
    }

    const statusId = this.statusId !== 'all' ? this.statusId : null;
    const query = {
      [idsKey]: ids,
    };

    if (statusKey && statusId) {
      query[statusKey] = statusId;
    }

    return await entityClass.deleteItemsFunc(query);
  }

  async doBulkAction(operationKey, payload = {}) {
    if (!this.selectedItems.length) {
      return;
    }

    switch (operationKey) {
      case 'set_status':
        await this.bulkStatusChange(this.selectedItems, payload.statusId);
        break;
      case 'delete':
        await this.bulkDelete(this.selectedItems);
        break;
      case 'print':
        this.goToPrint({
          ids: this.selectedItems,
        });
        break;
      case 'excel':
        await this.exportToExcel({
          ids: this.selectedItems,
          fileName: payload.fileName,
        });
        break;
      default:
    }
  }

  async reloadItemsAfterBulkOperation() {
    const loadItemsFunc = this.selfClass.getLoadItemsFunc();
    const { isUserSection } = this.selfClass;
    // check isUserSection because we don't have remove action in leads
    const lastQuery = this.getLastQuery();
    const query = isUserSection ? lastQuery : {
      ...lastQuery,
      offset: 0,
      limit: this.selectedItems.length,
      filter: {
        id: {
          in: this.selectedItems,
        },
      },
    };

    try {
      const response = await loadItemsFunc(query);
      const newItems = new IndexedArray(response?.data || []);
      const changedItems = [];
      let preparedItems = new IndexedArray([]);
      let addedItems = [];

      if (isUserSection) {
        addedItems = newItems.filter(newItem => !this.items.findById(newItem.id));

        await this.loadMetaData(response.meta);
      }

      this.items.forEach(oldItem => {
        const newItem = newItems.findById(oldItem.id);

        if (newItem && this.selectedItems.includes(oldItem.id)) {
          changedItems.push(newItem);
        }
      });

      const itemsForPrepare = [...changedItems, ...addedItems];

      if (itemsForPrepare.length > 0) {
        preparedItems = await this.prepareItems(itemsForPrepare, lastQuery.fields);

        this.loadDatasetsBySettings(preparedItems);
        this.loadDatasets(itemsForPrepare, lastQuery.fields);

        await this.loadAdditionalValues(preparedItems, lastQuery.fields, this.currentFields);
      }

      if (isUserSection) {
        const items = newItems
          .map(newItem =>
            preparedItems.findById(newItem.id) ||
            this.items.findById(newItem.id))
          .filter(el => el);

        this.items = new IndexedArray(items);

        if (this.items.length === 0) {
          const previousPage = this.currentPage - 1;
          this.loadItems(previousPage > 1 ? previousPage : 1);
        }
      } else {
        preparedItems.forEach(preparedItem => {
          const itemIndex = this.items.findIndex(el => el.id === preparedItem.id);

          if (itemIndex !== -1) {
            Vue.set(this.items, itemIndex, preparedItem);
          }
        });
      }

      this.selectedItems = [];
    } catch (e) {}
  }

  async exportToExcel(payload = {}) {
    const { ids, fileName } = payload;
    const listQuery = this.getQuery();
    const fields = this.allCurrentFields;
    const loadItemsFunc = this.selfClass.getLoadItemsFunc();
    let query = {
      ...listQuery,
      limit: null,
      export: 'xlsx',
      export_data: fields.map(field => {
        const fieldset = this.userFieldset.find(el => el.key === field.key);
        return fieldset || { key: field.key };
      }),
    };

    if (ids) {
      query = mergeQueryParams(query, {
        jsFilter: {
          id: ids,
        },
      });
    }

    if (fileName) {
      query.export_file_name = fileName;
    }

    return await loadItemsFunc(query);
  }

  async loadItems(...args) {
    if (this.viewMode === ENTITY_LIST_VIEWS.MAP) {
      return this.loadMapItems(this.mapData.savedParams.mapSize, this.mapData.savedParams.bounds);
    } else {
      return this.loadListItems(...args);
    }
  }

  fetchContentStats() {
    if (!this.selfClass.getEntityProperty('hasContentStats') ||
      !this.selfClass.isUserSection) {
      return;
    }

    store.dispatch('Account/fetchUserContentStats', {
      entityTypeId: this.selfClass.getEntityProperty('entityTypeId'),
      isShowLocked: this.isShowLockedItems,
    });
  }

  async toggleShowLockedItems() {
    this.isShowLockedItems = !this.isShowLockedItems;

    store.commit('SET_LIST_VALUE', {
      listTypeId: this.listTypeId,
      key: 'isShowLockedItems',
      value: this.isShowLockedItems,
    });

    await this.loadListItems(1);
    await this.fetchContentStats();
  }
}
