/*
  PMActivity class
 */

import BaseEntity from '@/core/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getPMActivities } from "@/api/repositories/parabygMigrationRepository";
import { Client } from "@/entities";
import { formatDateTime } from "@/shared/utils";
import Vue from "vue";

const defaultData = () => ({
  id: null,
  pm_client_version_id: null,
  source_id: null,
  responsible: '',
  responsible_ref: null,
  rsm_project_id: null,
  rsm_company_id: null,
  rsm_contact_id: null,
  done: null,
  start_date_time: null,
  subject: '',
  text: null,
  entity_type_id: null,
  entity_id: null,
  is_valid: null,
  validation_error_message: null,
  is_migrated: null,
  migration_error_message: null,
  user_id: null,
});

export class PMActivity extends BaseEntity {
  static entityKey = 'pm_activity';
  static datasetKey = 'pmActivities';
  static loadAllItemsFunc = getPMActivities;
  static entityTypeId = ENTITY_TYPES.PM_ACTIVITY;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('parabyg_migration.activities_plural_text.text');
  }

  constructor(...args) {
    super(defaultData, ...args);

    this.selfClass = PMActivity;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      case 'entity_type_id':
        res = this.getEntityType();
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    let res;

    switch (fieldKey) {
      case 'client_id':
        res = this.getClient(dataset);
        break;
      case 'user_id':
        res = this.getUser(dataset);
        break;
      default:
    }

    return res;
  }

  getClient(dataset = {}) {
    return this.getDatasetItem(dataset, Client.datasetKey, this.getValue('client_id'));
  }

  getEntityType() {
    const entityTypes = _.invert(ENTITY_TYPES);
    const listTypeId = this.getValue('entity_type_id');
    return entityTypes[listTypeId];
  }
}
