/*
  ClientUsersList class
 */

import EntityList from "@/core/classes/list/EntityList";
import ClientUsersTableView from "@/entities/client-user/table-views/ClientUsersTableView.vue";
import Vue from "vue";
import { getListUsersListsConfig } from "@/entities/client-user/config";
import { ClientUser, User, Client } from "@/entities";
import {
  SEARCH_FILTER_TYPES,
  SEARCH_FIELD_TYPE, LIST_TYPES,
} from "@/config/enums";

export class ClientUsersList extends EntityList {
  static entityClass = ClientUser;

  constructor(listTypeId) {
    super(listTypeId, getListUsersListsConfig);

    this.client = null;
    this.tableView = ClientUsersTableView;
    this.selfClass = ClientUsersList;
  }

  getListSearchFields() {
    return [
      {
        fieldKey: 'keywords',
        fieldType: SEARCH_FIELD_TYPE.CUSTOM,
        fieldAttrs: {
          dataCy: 'search-keywords',
        },
        filterType: SEARCH_FILTER_TYPES.KEYWORDS,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('users.filter_keywords_input_placeholder.text'),
          autocomplete: true,
          autocompleteSettings: {
            autocompleteCallback: async (searchText, query) => {
              let response;

              try {
                response = await ClientUser.loadAllItemsFunc({
                  ...query,
                  search: searchText,
                });
              } catch (e) {}

              return {
                items: (response?.data || []).map(clientUser => {
                  const { user } = clientUser;

                  return {
                    id: user.id,
                    name: user.name,
                    email: user.email,
                  };
                }),
                total: response?.meta?.total,
              };
            },
            optionComponent: () => import("@/entities/user/views/UserOption.vue"),
            routeName: User.routes.view,
          },
        },
      },
    ];
  }

  getExportFileNamePrefix() {
    const clientName = this.client?.getName();
    let prefix;

    if (this.listTypeId === LIST_TYPES.CLIENT_USERS && clientName) {
      // TODO Change regex for replace symbols for all languages
      const clientNamePrefix = clientName
        .toLowerCase()
        .replace(/[^ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚÓa-z1-9\-()]+/g, '_')
        .replace(/_$/, '');
      prefix = `${clientNamePrefix}_users`;
    }

    return prefix || super.getExportFileNamePrefix();
  }
}
