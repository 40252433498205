import Vue from 'vue';
import BaseEntity from '@/core/classes/entity/BaseEntity';
import {
  ENTITY_TYPES,
} from '@/config/enums';
import {
  getCustomFieldsets,
  getCustomFieldset,
  patchCustomFieldset, deleteCustomFieldset
} from "@/api/repositories/customFieldsRepository";
import { formatDateTime } from "@/shared/utils";

export class CustomFieldset extends BaseEntity {
  static entityKey = 'custom_fieldset';
  static datasetKey = 'custom_fieldset';
  static routes = {
    list: 'SystemCustomFieldsetTemplates',
    edit: 'SystemCustomFieldsetTemplateEditor',
  };
  static entityTypeId = ENTITY_TYPES.CUSTOM_FIELDSET;

  // api
  static loadAllItemsFunc = getCustomFieldsets;
  static loadItemFunc = getCustomFieldset;
  static patchItemFunc = patchCustomFieldset;
  static deleteItemFunc = deleteCustomFieldset;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('custom_fieldset.templates_plural_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = CustomFieldset;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'entity_type_id':
        res = this.getSection()?.name || '';
        break;
      case 'created_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getSection() {
    const sections = this.selfClass.getSections();
    return sections.find(item => item.id === this.getValue('entity_type_id'));
  }

  static getSections() {
    return [
      {
        id: ENTITY_TYPES.PROJECT,
        name: Vue.prototype.$vDict(`enums.list_type_user_projects.text`),
        icon: 'building-construction',
      },
      {
        id: ENTITY_TYPES.TENDER,
        name: Vue.prototype.$vDict(`enums.list_type_user_tenders.text`),
        icon: 'tender-lg',
      },
      {
        id: ENTITY_TYPES.COMPANY,
        name: Vue.prototype.$vDict(`enums.list_type_user_companies.text`),
        icon: 'company-lg',
      },
      {
        id: ENTITY_TYPES.CONTACT,
        name: Vue.prototype.$vDict(`enums.list_type_user_contacts.text`),
        icon: 'contacts',
      },
    ];
  }
}
