/*
  MyranSuspendedUsersList class
 */

import EntityList from "@/core/classes/list/EntityList";
import {
  SEARCH_FILTER_TYPES,
  SEARCHABLE_LIST_TYPES,
} from "@/config/enums";
import MyranSuspendedUsersTableView from "@/entities/myran-suspended-user/views/MyranSuspendedUsersTableView";
import { getMyranSuspendedUsersListsConfig } from "@/entities/myran-suspended-user/config";
import { requiredFields } from "@/entities/myran-suspended-user/fields";
import { getUsers } from "@/api/repositories/userRepository";
import { MyranSuspendedUser } from "@/entities";

export class MyranSuspendedUsersList extends EntityList {
  static entityClass = MyranSuspendedUser;

  constructor(listTypeId) {
    super(listTypeId, getMyranSuspendedUsersListsConfig);

    this.tableView = MyranSuspendedUsersTableView;
    this.settingsForLoadingDatasets = [
      {
        datasetKey: 'users',
        fieldKeys: ['user_id'],
        loadFunc: getUsers,
      },
    ];

    this.selfClass = MyranSuspendedUsersList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting() {
    return [
      {
        key: 'id',
        dataCy: 'id',
        name: 'Id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type id',
        },
      },
      {
        key: 'client_version_id',
        dataCy: 'client-version',
        name: 'Client version id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type client version id',
        },
      },
      {
        key: 'source_id',
        dataCy: 'source',
        name: 'Source Id',
        filterType: SEARCH_FILTER_TYPES.INPUT_ARRAY,
        filterAttrs: {
          placeholder: 'Type source id',
        },
      },
      {
        key: 'user_id',
        dataCy: 'user',
        name: 'User',
        filterType: SEARCH_FILTER_TYPES.SEARCHABLE_LIST,
        filterAttrs: {
          placeholder: 'Type user name',
          type: SEARCHABLE_LIST_TYPES.USERS,
        },
      },
      {
        key: 'created_at',
        dataCy: 'created-at',
        name: 'Created at',
        filterType: SEARCH_FILTER_TYPES.SMO_DATE_RANGE,
      },
    ];
  }
}
